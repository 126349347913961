.form_spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99999;
    text-align: center;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    background: rgba(0, 0, 0, 0.04);
}

.form_spinner i {
    margin-top: 200px;
}
