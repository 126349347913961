.dynamic_text_input .input-group {
    margin-bottom: 10px;
    position: relative;
}

.dynamic_text_input .form-control.is-invalid {
    padding-right: 0;
}

.field_settings_list {
    position: absolute;
    left: 0;
    background: white;
    top: 44px;
    border: 1px solid #efefef;
}

.field_settings_list ul {
    width: 100%;
    padding: 5px 10px;
    height: 100%;
    max-height: 150px;
    overflow-y: scroll;
}

.field_settings_list ul li {
    cursor: pointer;
    width: 100%;
    display: block;
    border-bottom: 1px solid #efefef;
}

.field_settings_list ul li:last-child {
    border: none;
}

.dynamic_field_floating_label {
    position: absolute;
    right: 25px;
    bottom: 2px;
    color: #818181;
    z-index: 5;
}
