#at2_push_notification_popup {
    width: 33.3333333333%;
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9999;
}

.at2_push_notification {
    width: 100%;
    position: relative;
    right: 15px;
    top: 0;
    margin-top: 10px;
    border-radius: 8px;
    background-color: #f7f7ed;
    text-align: center;
    font-size: 18px;
    transition-duration: 1s;
    overflow: hidden;
    border: solid 1px #76af1b;
    padding: 23px 15px;
}

#at2_push_notification_popup.hide {
    border: none;
    max-height: 0;
    overflow: hidden;
    transition-duration: .25s;
}

#at2_push_notification_popup p {
    padding: 0;
    margin: 0;
}


#at2_push_notification_popup .fa-times {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px;
    -webkit-text-stroke: 2px;
    -webkit-text-stroke-color: #f7f7ed;
    line-height: 16px;
}

@media only screen and (max-width: 767px) {
    #at2_push_notification_popup {
        width: 80%;
    }
}
