#at2_popup .price_module_popup {
    max-width: 800px;
    padding: 30px 60px;
}


.period_group {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 10px 10px 10px;
    margin-top: 15px;
}


.period_group .period_group_label {
    position: absolute;
    top: 0;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    background-color: rgba(247, 247, 237, 1);
}

/* CSS for Responsive Mobile View */

@media only screen and (max-width: 767px) {
    #at2_popup .price_module_popup {
        padding: 15px;
        max-height: 100%;
        overflow-y: scroll;
    }

    #at2_popup .price_module_popup.forward_popup {
        top: 15px !important;
        margin-top: 0 !important;
    }

    .period_group {
        margin-bottom: 10px;
    }
}
