.input-password {
    width: 100%;
    position: relative;
}

.input-password i.fa {
    color: #8C8C8C;
    position: absolute;
    right: 5px;
    top: 11px;
    cursor: pointer;
    z-index: 99;
}

.input-password .form-control.is-invalid {
    background-image: none;
}
