button.at2-icon-btn,
button.at2-icon-btn:focus,
button.at2-icon-btn:hover
{
    font-size: 12px;
    font-weight: 600;
    color: white;
    outline: none;
    background-color: #102640;
    border: none;
    box-shadow: none;
    border-radius: 50%;
    padding: 0;
    margin: 0 0 0 15px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

button.at2-icon-btn i {
    margin-right: 0;
    text-align: center;
    margin-top: 8px;
}

button.at2-icon-btn img {
    margin-top: -4px;
}

/* Model reset to default icon */

.model-action-tabs .reset_to_default {
    cursor: pointer;
    margin-left: 15px;
}
