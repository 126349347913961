.welcome-block{
    background-color: #e0eaeb;
    padding: 25px 25px 30px;
}

.welcome-block h1 {
    font-size: 32px;
    line-height: 34px;
    font-weight: 600;
}

.welcome-block p {
    font-size: 18px;
    padding: 0;
    margin: 0;
    line-height: 1.33;
}
