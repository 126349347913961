.model_trial_period {
    padding-top: 8px;
    position: absolute;
    right: 0;
    width: 60%;
}

.model_trial_period .label {
    display: inline-block;
    float: left;
    width: 11%;
}

.model_trial_period input {
    width: 15%;
    float: left;
    height: 25px;
    margin-bottom: 0;
    padding: 3px;
}

.model_trial_period .duration_text {
    display: inline-block;
    padding-left: 5px;
}


/* CSS for Responsive Mobile View */

@media only screen and (min-width: 992px) and (max-width: 1450px) {
    .model_trial_period {
        width: 50%;
    }

}
